import { Get, Post } from "../utils/ApiUtility";

export const PaymentService = () => {
  const getToken = () => localStorage.getItem("WPV_token");

  const getPaymentList = async (plan_id,mobile) => {
    const token = getToken();
    const url = `nimbbl-init`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      appid: "8",
    };

    return await Post(url, {plan_id,mobile}, headers);
  };

  const getTransactions = async (page) => {
    const token = getToken();
    const url = `wallet-history-latest-new?page=${page}&start_date=&end_date=&type=all`
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "8",
      "Content-Type": "application/json",
    };

    return await Get(url, headers);
  }

  const getPaymentListPublic = async (plan_id,mobile,token) => {
    const url = `payment-init-whatapp?plan_id=${plan_id}`;
    const headers = {
      "Content-Type": "application/json",
      appid: "8"
    };

    return await Post(url, { fcm_token: token,mobile}, headers);
  };

  const checkPaymentStatusByOrderId = async (requestBody) => {
    const url = `payment-check-whatsapp`;
    const headers = {
      "Content-Type": "application/json",
      appid: "8"
    };

    return await Post(url, requestBody, headers);
  };

  const paymentCheckWhatsappAuth = async (requestBody) => {
    const token = getToken();
    const url = `payment-check-whatsapp-auth`;
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "8",
      "Content-Type": "application/json",
    };

    return await Post(url, requestBody, headers);
  };


  return { getPaymentList, getTransactions, getPaymentListPublic, checkPaymentStatusByOrderId, paymentCheckWhatsappAuth};
};
