import React from "react";
import "./PwaInstallationPopup.css";
import pwaIcon from '../../assets/images/pwa-popup.svg';
import closeIcon from '../../assets/images/closeIcon.svg';
import Lottie from "lottie-react";
import cercleLoader from "../../assets/images/circle-loader.json";
import PwaStatus from "../../constant/PwaStatus";

function PwaInstallationPopup({ pwaInstallStatus, handleInstall, openApp, onClose }) {
  return (
    // <>
    //   <div className="popup-overlay-pwa-installation">
    //     <div className="popup-box-pwa-installation">
    //       <div className="popup-close-icon-outer" onClick={onClose}>
    //         <img src={closeIcon} alt="close" />
    //       </div>
    //       <div className="popup-box-pwa-installation-top-image">
    //         <img src={pwaIcon} alt="PWA Icon" />
    //       </div>
    //       <div className="popup-text-outer-pwa-installation">
    //         <p className="popup-text-pwa-installation">
    //           Install the WhatsApp Girls app for a better experience on your device.
    //         </p>
    //       </div>
    //       <div className="button-group-pwa-installation mt-2">
    //         {
    //           pwaInstallStatus === PwaStatus.Install && (
    //             <div onClick={handleInstall} className="confirm-button-pwa-installation">
    //               <p className="confirm-text-pwa-installation">Install Now</p>
    //             </div>
    //           )
    //         }
    //         {
    //           pwaInstallStatus === PwaStatus.Installing && (
    //             <div className="confirm-button-pwa-installing">
    //               <p className="confirm-text-pwa-installation">Installing</p>
    //               <Lottie animationData={cercleLoader} loop={true} className="animation-installing" />
    //             </div>
    //           )
    //         }
    //         {
    //           pwaInstallStatus === PwaStatus.Installed && (
    //             <div onClick={openApp} className="confirm-button-pwa-installation">
    //               <p className="confirm-text-pwa-installation">Open App</p>
    //             </div>
    //           )
    //         }

    //       </div>
    //     </div>
    //   </div>
    // </>

    <>
      <div className="popup-overlay-pwa-installation">
        <div className="wrapper">
          <div className="header">
             {/* production   */}
             <img className="app-logo" src="https://private.appvideocall.com/zeeplive/th.jpeg" alt="Logo" /> 
             {/* staging  */}
            {/* <img className="app-logo" src="https://photosnow.org/wp-content/uploads/2024/04/beautiful-girl-photo_2.jpg" alt="Logo" /> */}

            <div className="app-info_block">
              <h1>
                <span className="app-name"> Whatsapp Calls - Sexy Video Call & Chat</span>
              </h1>
              <div className="app-info_main">
                <span>Video Call App</span>
              </div>
            </div>
          </div>
          <div className="add-info_block">
            <div className="info info_first">
              <span>4.7 <img src="https://private.appvideocall.com/zeeplive/star.svg" alt="" /></span>
              <p>48.7K reviews</p>
            </div>
            <div className="info info_second">
              <span>500K+ </span>
              <p>Downloads</p>
            </div>
            <div className="info info_third">
              <img className="pegi" src="https://private.appvideocall.com/zeeplive/pegi.svg" alt="" />
              <p>Rated for 18+</p>
            </div>
          </div>

          {pwaInstallStatus === PwaStatus.Install && (
            <div className="actions-block">
              <a onClick={handleInstall} className="btn install-btn">Install</a>
              <a className="btn" href="#"></a>
              <a href="#" className="wishlist-btn">
                <img src="https://private.appvideocall.com/zeeplive/bookmark.svg" alt="Bookmark" /> Add to Wishlist
              </a>
              <span className="ad-contains">Contains Ads</span>
            </div>
          )}

          {pwaInstallStatus === PwaStatus.Installing && (
            <div className="actions-block">
              <a className="btn install-btn" style={{ opacity: 0.6 }}>
                Installing...
              </a>

              <a className="btn" href="#"></a>
              <a href="#" className="wishlist-btn">
                <img src="https://private.appvideocall.com/zeeplive/bookmark.svg" alt="Bookmark" /> Add to Wishlist
              </a>
              <span className="ad-contains">Contains Ads</span>
            </div>
          )}

          {pwaInstallStatus === PwaStatus.Installed && (
            <div className="actions-block" >
              <a onClick={openApp} className="btn install-btn">Open</a>
              <a className="btn" href="#"></a>
              <a href="#" className="wishlist-btn">
                <img src="https://private.appvideocall.com/zeeplive/bookmark.svg" alt="Bookmark" /> Add to Wishlist
              </a>
              <span className="ad-contains">Contains Ads</span>
            </div>
          )}


          <div className="app-photo_block btn">
            {["2.mp4", "3.mp4", "1.mp4"].map((video, index) => (
              <a href="#" key={index}>
                 {/* production  */}
                <video className="preview-video" muted autoPlay loop>
                  <source src={`https://private.appvideocall.com/zeeplive/${video}`} type="video/mp4" />
                </video>

                 {/* staging  */}
                {/* <img className="preview-video" src="https://photosnow.org/wp-content/uploads/2024/04/beautiful-girl-photo_13.jpg" alt="girls-img" /> */}
              </a>
            ))}

          </div>
          <div className="about">About this app <img className="arrow" src="https://private.appvideocall.com/zeeplive/arrow.svg" alt="" /></div>
          <div className="app-desc">Top App in Entertainment! Watch and download new hot videos every day 🔥🔥🔥</div>
          <div className="up-block">
            <span className="up-block_title">Updated on</span>
            <p className="up-block_desc">Feb 7, 2023</p>
          </div>
          <div className="data-block">
            <span className="data-block_title">Data safety</span>
            <p className="data-block_desc">Developers can show information here about how their app collects and uses your data.</p>
          </div>
          <div className="reviews-block">
            <div className="reviews-header">
              <h2 className="reviews-header_name">Ratings and reviews</h2>
              <div className="policy-block">
                <a href="#" className="policy-btn btn">
                  <img className="arrow" src="https://private.appvideocall.com/zeeplive/arrow.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="verified-block">Ratings and reviews are verified <img src="https://private.appvideocall.com/zeeplive/info.png" alt="" /></div>
            <div className="rate-blocks">
              <div className="rate_first-block">
                <span className="rate-big">4.6</span>
                <a href="#" className="rate btn">
                  {[...Array(5)].map((_, i) => (
                    <img key={i} className="star-main" src="https://private.appvideocall.com/zeeplive/star-green.svg" alt="" />
                  ))}
                </a>
                <span className="rate-total">48.7K reviews</span>
              </div>
            </div>
            <div className="comment-block">
              {[{
                name: "Sanjay Kumar",
                date: "January 30, 2022",
                text: "This app is so good! We are waiting for new HOT content!",
                img: "user1.jpg"
              }, {
                name: "Eduard Tereshko",
                date: "April 22, 2022",
                text: "High quality content! After watching the first video, it is impossible to stop at the second",
                img: "E"
              }, {
                name: "Asha Devi",
                date: "March 19, 2022",
                text: "I'm really loving this app. 💦💦💦",
                img: "user2.jpg"
              }].map((comment, index) => (
                <div className="comment" key={index}>
                  <div className="first_comment-block">
                    <div className="photo-block">
                      {comment.img.includes(".") ? <img className="user-photo" src={`https://private.appvideocall.com/zeeplive/${comment.img}`} alt="" /> : <div className="photo_undefined"><span>{comment.img}</span></div>}
                      <span className="comment-author_name">{comment.name}</span>
                    </div>
                  </div>
                  <div className="second_comment-block">
                    <div className="comment-author">
                      <div className="comment-author_rate">
                        <a href="#" className="rate btn">
                          {[...Array(5)].map((_, i) => (
                            <img key={i} className="star-comment" src="https://private.appvideocall.com/zeeplive/star-green.svg" alt="" />
                          ))}
                        </a>
                        <span className="comment-author_time">{comment.date}</span>
                      </div>
                    </div>
                    <div className="comment-text">{comment.text}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PwaInstallationPopup;
