import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";
import SplashScreen from "../pages/features/splashScreen/SplashScreen";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { CountryProvider } from "../context/CountryContext";
import TestScreen from "../pages/features/test/Test";
import HostUnavailablePopup from "../components/popup/hostUnavailablepopup/HostUnavailablePopup";
import Test from "../pages/features/test/Test";

const TransactionsScreen = lazy(() => import("../pages/features/transectionsScreen/TransectionsScreen"))
// const MobileRegistration = lazy(() => import("../pages/auth/MobileRegistration"));
// const OTPVerification = lazy(() => import("../pages/auth/OTPverifiction"));
// const UserSignup = lazy(() => import("../pages/auth/UserSignup"));
const HomeScreen = lazy(() => import("../pages/features/homeScreen/HomeScreen"));
const CallScreen = lazy(() => import("../pages/features/callScreen/CallDetailsScreen"));
const LiveBroadCastListScreen = lazy(() => import("../pages/features/liveBroadCastListScreen/LiveBroadCastListScreen"));
const ProfileScreen = lazy(() => import("../pages/features/profileScreen/ProfileScreen"));
const MyBalanceScreen = lazy(() => import("../pages/features/myBalanceScreen/MyBalanceScreen"));
const LiveStremingScreen = lazy(() => import("../pages/features/liveBroadScreen/LiveStreamingScreen"));
const FavoriteHostScreen = lazy(() => import("../pages/features/favoriteHostScreen/FavouiteHostScreen"));
const ChatScreen = lazy(() => import("../pages/features/chatScreen/ChatScreen"));
const CountryCode = lazy(() => import("../pages/features/countryCodeScreen/CountryCode"));
const VideoCallScreen = lazy(() => import("../pages/features/videoCallScreen/VideoCallScreen"));
const RechargeScreen = lazy(() => import("../pages/features/rechargeScreen/RechargeScreen"));
const SessionExpired = lazy(() => import("../pages/auth/sessionExpired/SessionExpired"));
const FaceCallScreen = lazy(() => import("../pages/features/fakeCallPage/FakeCallPage"));
const ScratchRewardScreen = lazy(() => import("../pages/features/scratchWinReward/ScratchToWin"));


export default function PrivateRoute() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        {/* <Route
          path="/register"
          element={
            <CountryProvider>
              <PublicRoute element={MobileRegistration} />
            </CountryProvider>
          }
        />
        <Route
          path="/verify-otp"
          element={<PublicRoute element={OTPVerification} />}
        />
        <Route path="/sign-up" element={<PublicRoute element={UserSignup} />} /> */}
        <Route path="/home" element={<ProtectedRoute element={HomeScreen} />} />
        <Route
          path="/calling"
          element={<ProtectedRoute element={CallScreen} />}
        />
        <Route
          path="/favorite"
          element={<ProtectedRoute element={FavoriteHostScreen} />}
        />
        <Route
          path="/live-broadcast-list"
          element={<ProtectedRoute element={LiveBroadCastListScreen} />}
        />
        <Route
          path="/user-profile"
          element={<ProtectedRoute element={ProfileScreen} />}
        />
        <Route
          path="/balance-overview"
          element={<ProtectedRoute element={MyBalanceScreen} />}
        />
        <Route
          path="/test"
          element={<Test/>}
        />
        <Route
          path="/live-streaming"
          element={<ProtectedRoute element={LiveStremingScreen} />}
        />
        <Route
          path="/transaction"
          element={<ProtectedRoute element={TransactionsScreen} />}
        />
        <Route path="/chat-screen" element={<ProtectedRoute element={ChatScreen} />} />
        <Route
          path="/country-code"
          element={
            <CountryProvider>
              <PublicRoute element={CountryCode} />
            </CountryProvider>
          }
        />
        <Route
          path="/video-call"
          element={<ProtectedRoute element={VideoCallScreen} />}
        />
        <Route
          path="/recharge"
          element={<ProtectedRoute element={RechargeScreen} />}
        />

        <Route
          path="/fake-call"
          element={<ProtectedRoute element={FaceCallScreen}/> }
        />
        <Route
          path="/scratch-reward"
          element={<PublicRoute element={ScratchRewardScreen} />} 
        />
      </Routes>
    </Suspense>
  );
}
  