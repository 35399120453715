import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileService } from '../services/ProfileService';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toastNotification, setToastNotification] = useState({ message: {}, isVisible: false });
  const [toastCallback, setToastCallback] = useState(null);
  const [messageCounter, setMessageCounter] = useState(0);
  const [hasUserBalance, setHasUserBalance] = useState(true);
  const navigate = useNavigate();
  const profileService = ProfileService();

  useEffect(() => {
    const userToken = localStorage.getItem("WPV_token");
    const offlineMessagesStored = JSON.parse(localStorage.getItem("WPV_OfflineMessagesList")) || [];
    let activeUserIndex = Number(localStorage.getItem("WPV_ActiveUserIndex")) || 0;
    if (!userToken) {
      if (offlineMessagesStored.length > 0) {
        if (activeUserIndex + 1 < offlineMessagesStored.length){
          setTimeout(() => {
            processOfflineMessages();
          }, 8000);
        }
      }
      else {
        fetchOfflineMessages();
      }
    }
  }, []);

  useEffect(() => {
    if (hasUserBalance) return;
    const offlineMessagesStored = JSON.parse(localStorage.getItem("WPV_OfflineMessagesList")) || [];
    let activeUserIndex = Number(localStorage.getItem("WPV_ActiveUserIndex")) || 0;
    if (offlineMessagesStored.length > 0) {
      if (activeUserIndex + 1 < offlineMessagesStored.length){ 
        setTimeout(() => {
          processOfflineMessages();
        }, 8000);
      };
    }
    else {
      fetchOfflineMessages();
    }
  }, [hasUserBalance]);

  const fetchOfflineMessages = async () => {
    try {
      const response = await profileService.getOfflineMessages();
      if (response?.success) {
        localStorage.setItem("WPV_OfflineMessagesList", JSON.stringify(response.result));
        localStorage.setItem("WPV_ActiveUserIndex", 0);
        localStorage.setItem("WPV_ProcessedOfflineMessages", JSON.stringify([]));
        setTimeout(() => {
          const userToken = localStorage.getItem("WPV_token");
          if (userToken) return;
          processOfflineMessages();
        }, 18000);
      }
    } catch (error) {
      console.error("Error fetching offline messages:", error);
    }
  };

  const navigateToChatScreen = (chatData) => {
    setToastNotification({ message: '', isVisible: false });
    setToastCallback(null);
    const route = sessionStorage.getItem('WPV_currentRouteName');
    let replace = false;
    if (route === 'scratch-reward' || route === 'recharge') {
      replace = true;
    }
    navigate('/chat-screen', { state: chatData, replace: replace });
  };


  const processOfflineMessages = async () => {
    let offlineMessages = JSON.parse(localStorage.getItem("WPV_OfflineMessagesList")) || [];
    let activeUserIndex = Number(localStorage.getItem("WPV_ActiveUserIndex")) || 0;

    if (activeUserIndex >= offlineMessages.length) return;

    let currentUser = offlineMessages[activeUserIndex];
    let messageIndex = Number(localStorage.getItem(`WPV_MessageIndex_${currentUser.user_id}`)) || 0;

    const sendNextMessage = () => {
      if (messageIndex < currentUser.data.length) {
        let message = currentUser.data[messageIndex];
        let processedMessages = JSON.parse(localStorage.getItem("WPV_ProcessedOfflineMessages")) || [];
        let hostLocation = JSON.parse(localStorage.getItem("WPV_host_location")) || [];
        let locationName = (Array.isArray(hostLocation) &&
          hostLocation[activeUserIndex] &&
          hostLocation[activeUserIndex].name)
          ? hostLocation[activeUserIndex].name
          : 'Delhi';
        let userIndex = processedMessages.findIndex(user => user.user_id === currentUser.user_id);

        if (userIndex === -1) {
          processedMessages.push({
            user_id: currentUser.user_id,
            profile_id: currentUser.profile_id,
            name: currentUser.name,
            call_rate: currentUser.call_rate,
            profile_image: currentUser.profile_image,
            data: [message],
            time: Math.floor(Date.now() / 1000),
            location: locationName
          });
        } else {
          processedMessages[userIndex].data.push(message);
        }

        localStorage.setItem("WPV_ProcessedOfflineMessages", JSON.stringify(processedMessages));
        localStorage.setItem(`WPV_MessageIndex_${currentUser.user_id}`, messageIndex + 1);
        setMessageCounter(prev => prev + 1);

        const newMessageObject = {
          user_id: currentUser.user_id,
          profile_id: currentUser.profile_id,
          name: currentUser.name,
          call_rate: currentUser.call_rate,
          profile_image: currentUser.profile_image,
          data: message,
          time: Math.floor(Date.now() / 1000)
        };
        localStorage.setItem("WPV_host_profile_id", currentUser.profile_id);
        const route = sessionStorage.getItem('WPV_currentRouteName');
        if (!["scratch-reward", "recharge"].includes(route)) {
          showToast(newMessageObject, () =>
            navigateToChatScreen({ profile_id: currentUser.profile_id, fakeChat: true })
          );
        }
        messageIndex++;
        setTimeout(() => {
          const userToken = localStorage.getItem("WPV_token");
          if (userToken) return;
          sendNextMessage();
        }, 12000);
      } else {
        const userToken = localStorage.getItem("WPV_token");
        if (userToken) return;
        initiateFakeCallForUser(currentUser, activeUserIndex, offlineMessages.length);
      }
    };

    sendNextMessage();
  };

  const initiateFakeCallForUser = (userDetails, currentIndex, totalUsers) => {
    let offlineMessages = JSON.parse(localStorage.getItem("WPV_ProcessedOfflineMessages")) || [];
    if (currentIndex < totalUsers && offlineMessages[currentIndex]?.data) {
      let videoURL = offlineMessages[currentIndex].data.find(msg => msg.fake_video === 1)?.video || "";
      const callId = localStorage.getItem("WPV_call_already_initiated");
      if (callId != userDetails.profile_id) {
        localStorage.setItem("WPV_call_already_initiated", userDetails.profile_id);
        const route = sessionStorage.getItem('WPV_currentRouteName');
        if (!["scratch-reward", "recharge"].includes(route)) {
          startFakeCall(userDetails.profile_id, userDetails.user_id, userDetails.profile_image, userDetails.name, videoURL, userDetails.call_rate);
        }
      }
    }
    if (currentIndex + 1 < totalUsers) {
      let interval;
      interval = setTimeout(() => {
        const userToken = localStorage.getItem("WPV_token");
        if (userToken) {
          clearInterval(interval);
          return;
        }
        setMessageCounter(0);
        localStorage.setItem("WPV_ActiveUserIndex", currentIndex + 1)
        processOfflineMessages();
        clearInterval(interval);
      }, 40000);
    }
  };

  const startFakeCall = (profileId, user_id, profileImage, profileName, videoURL, callrate) => {
    const userToken = localStorage.getItem("WPV_token");
    const currentRoute = sessionStorage.getItem("WPV_currentRouteName") || null;
    if (!userToken) {
      let replace = false;
      if (currentRoute === 'scratch-reward' || currentRoute === 'recharge') {
        replace = true;
      }
      navigate("/fake-call", {
        state: {
          profile_image: profileImage,
          profile_name: profileName,
          video_url: videoURL,
          profile_id: profileId,
          id: user_id,
          callRate: callrate,
          from: currentRoute ? `/${currentRoute}` : null,
        },
        replace: replace
      });
    }
  };

  const showToast = (message, onClickCallback) => {
    setToastNotification({ message, isVisible: true });
    setToastCallback(() => onClickCallback);

    setTimeout(() => {
      setToastNotification({ message: '', isVisible: false });
      setToastCallback(null);
    }, 5000);
  };

  return (
    <ToastContext.Provider value={{ toastNotification, toastCallback, messageCounter, setHasUserBalance }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

