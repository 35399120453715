import React, { createContext, useContext, useState } from "react";

const CountryContext = createContext();

export const useCountryContext = () => {
    return useContext(CountryContext);
};

export const CountryProvider = ({ children }) => {
    const [selectedCountry, setSelectedCountry] = useState(null);

    const value = {
        selectedCountry,
        setSelectedCountry,
    };

    return <CountryContext.Provider value={value}>{children}</CountryContext.Provider>;
};
