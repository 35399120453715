import React, { useRef, useEffect, useState } from "react";

function Test() {
  const middleRef = useRef(null);
  const inputTextRef = useRef(null);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  useEffect(()=>{
    if(isIOS){
      handleIOSLogic();
    }else{
      handleAndroidLogic();
    }
  },[]);

  const handleAndroidLogic = ()=>{
    document.body.style.overflow = 'hidden';
    const preventBodyScroll = (e) => {
      if (middleRef.current && !middleRef.current.contains(e.target)) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventBodyScroll, { passive: false });
    const handleTouchStart = (e) => {
      if (!middleRef.current) return;
      const target = middleRef.current;
      const startY = e.touches[0].clientY;

      const handleTouchMove = (event) => {
        const currentY = event.touches[0].clientY;
        const deltaY = currentY - startY;
        const isAtTop = target.scrollTop <= 0;
        const isAtBottom = target.scrollTop + target.clientHeight >= target.scrollHeight;

        if ((isAtTop && deltaY > 0) || (isAtBottom && deltaY < 0)) {
          event.preventDefault();
        }
      };

      target.addEventListener("touchmove", handleTouchMove, { passive: false });

      const handleTouchEnd = () => {
        target.removeEventListener("touchmove", handleTouchMove);
        target.removeEventListener("touchend", handleTouchEnd);
      };

      target.addEventListener("touchend", handleTouchEnd, { passive: false });
    };

    middleRef.current?.addEventListener("touchstart", handleTouchStart, { passive: false });
    const handleKeyboard = () => {
      if (!middleRef.current) return;
      const viewportHeight = window.visualViewport?.height || window.innerHeight;
      const keyboardHeight = window.innerHeight - viewportHeight;
      const isKeyboard = keyboardHeight > 0;
      setIsKeyboardVisible(isKeyboard);
      if (isKeyboard) {
        const newHeight = viewportHeight - 120;
        requestAnimationFrame(() => {
          middleRef.current.style.height = `${newHeight}px`;
        });
        setTimeout(() => {
          middleRef.current.scrollTop = middleRef.current.scrollHeight;
        }, 100);
      } else {
        middleRef.current.style.height = 'calc(100% - 120px)';
        inputTextRef.current?.blur();
      }
    };

    window.visualViewport?.addEventListener('resize', handleKeyboard);

    return () => {
      document.removeEventListener("touchmove", preventBodyScroll);
      middleRef.current?.removeEventListener("touchstart", handleTouchStart);
      window.visualViewport?.removeEventListener('resize', handleKeyboard);
      document.body.style.overflow = '';
    };
  };

  const handleIOSLogic = () => {
    document.documentElement.style.overflow = "hidden";
    const adjustViewport = () => {
      if (!middleRef.current) return;
      setTimeout(() => {
        const viewportHeight = window.visualViewport?.height || window.innerHeight;
        const keyboardHeight = Math.abs(window.innerHeight - viewportHeight);
        requestAnimationFrame(() => {
          middleRef.current.style.height = `${viewportHeight - 120}px`;
          middleRef.current.style.overflowY = "auto"; 
          middleRef.current.style.WebkitOverflowScrolling = "touch"; 
        });

        
        setTimeout(() => window.scrollTo(0, 0), 10);
      }, 50);
    };
  
    const ensureInputVisible = () => {
      setTimeout(() => {
        if (inputTextRef.current) {
          const rect = inputTextRef.current.getBoundingClientRect();
          const viewportHeight = window.visualViewport?.height || window.innerHeight;
          if (rect.bottom > viewportHeight) {
            inputTextRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
          }
        }
      }, 100);
    };

    const preventOuterScroll = (e) => {
      if (!middleRef.current.contains(e.target)) {
        e.preventDefault();
      }
    };
  
    document.addEventListener("touchmove", preventOuterScroll, { passive: false });
  
    window.visualViewport?.addEventListener("resize", adjustViewport);
    inputTextRef.current?.addEventListener("focus", ensureInputVisible);
  
    return () => {
      document.removeEventListener("touchmove", preventOuterScroll);
      window.visualViewport?.removeEventListener("resize", adjustViewport);
      inputTextRef.current?.removeEventListener("focus", ensureInputVisible);
      document.documentElement.style.overflow = "";
    };
  };



  return (
    <div
      style={{
        height: "calc(var(--vh, 1vh) * 100)",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          height: "55px",
          backgroundColor: "green",
          flexShrink: 0,
          position: "relative",
          zIndex: 1,
        }}
      />
      <div
        ref={middleRef}
        style={{
          height: "calc(100% - 120px)",
          backgroundColor: "black",
          overflowY: "auto",
          overflowX: "hidden",
          WebkitOverflowScrolling: "touch",
          position: "relative",
          touchAction: isIOS ? "pan-y" : "auto",
          ...(!isIOS && {
            overscrollBehavior: "contain",
          }),
        }}
      >
        {[...Array(14)].map((_, i) => (
          <div key={i} style={{ color: "white", padding: "20px" }}>
            Scrollable Content {i + 1}
          </div>
        ))}
      </div>
      <div
        style={{
          height: "65px",
          backgroundColor: "red",
          flexShrink: 0,
          position: "relative",
          zIndex: 1,
        }}
      >
        <input
          ref={inputTextRef}
          type="text"
          placeholder="Type here..."
          style={{
            width: "100%",
            height: "100%",
            padding: "10px",
            border: "none",
            backgroundColor: "transparent",
            color: "white",
            outline: "none",
            fontSize: "16px",
          }}
        />
      </div>
      
     
    </div>
  );
}

export default Test;


