import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDtjUi0hl7aO3xKKTW-WliN4CTpdv7epC0",
  authDomain: "starmate-5ca71.firebaseapp.com",
  databaseURL: "https://starmate-5ca71-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "starmate-5ca71",
  storageBucket: "starmate-5ca71.appspot.com",
  messagingSenderId: "724993368278",
  appId: "1:724993368278:web:f82eb325de616353f6a977",
  measurementId: "G-EH21YTT9LS"
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
export const database = getDatabase(app);
