
import { getToken } from "firebase/messaging";
import { messaging } from "./firebaseConfig";

const VAPID_KEY = "BGLEvbG6O86Kk23aTah9-0c_sg3gryZOFa_qFVPzHfpY28NAoAqPUtzZvD4sa5jx3m_CgO66gtXJZoaLViDN1uc";

export const createFCMToken = async () => {
  try {
    return await getToken(messaging, { vapidKey: VAPID_KEY });
  } catch (error) {
    console.error("Error getting create FCM token:", error);
  }
}