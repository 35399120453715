import './Loader.css';

const Loader = () => (
  <>
    <div className="fallback-loader">
      <div className="fallback-loader-dots">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </>

);

export default Loader;
