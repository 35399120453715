import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
const StoreUiContext = createContext();

export const StoreUiProvider = ({ children }) => {
  const [chattingList, setChattingList] = useState([]);
  const [followingList, setfollowingList] = useState([]);
  const [profileDetailsData, setProfileDetailsData] = useState([]);
  const [isRecharged, setIsRecharged] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [hasFetchedFav, setHasFetchedFav] = useState(false);
  const [hasFetchedLive, setHasFetchedLive] = useState(false);
  const [hasFetchedProfile, setHasFetchedProfile] = useState(false);
  const [hasFakeFetchedLive, setHasFakeFetchedLive] = useState(false);
  const [hasFakeFetchedFav, setHasFakeFetchedFav] = useState(false);
  const [followingFakeList, setfollowingFakeList] = useState([]);
  const [liveBroadCastList, setLiveBroadCastList] = useState([]);
  const [liveFakeBroadCastList, setLiveFakeBroadCastList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFakePage, setCurrentFakePage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [fakeNextPageUrl, setFakeNextPageUrl] = useState(null);
  const [isFavListLoading, setIsFavListLoading] = useState(true);
  const [loadingHomeScreen, setLoadingHomeScreen] = useState(true);
  const [fakeChathostList, setFakeChathostList] = useState([]);
  const [haslocalChatFetched, setHaslocalChatFetched] = useState(false);

  

  const contextValue = useMemo(
    () => ({
      chattingList,
      setChattingList,
      hasFetched,
      setHasFetched,
      followingList,
      setfollowingList,
      liveBroadCastList,
      setLiveBroadCastList,
      hasFetchedFav,
      setHasFetchedFav,
      hasFetchedLive,
      setHasFetchedLive,
      profileDetailsData,
      setProfileDetailsData,
      hasFetchedProfile,
      isRecharged,
      setIsRecharged,
      setHasFetchedProfile,
      hasFakeFetchedLive,
      setHasFakeFetchedLive,
      liveFakeBroadCastList,
      setLiveFakeBroadCastList,
      followingFakeList,
      setfollowingFakeList,
      hasFakeFetchedFav,
      setHasFakeFetchedFav,
      setCurrentPage,
      currentPage,
      currentFakePage,
      setCurrentFakePage,
      setFakeNextPageUrl,
      fakeNextPageUrl,
      setNextPageUrl,
      nextPageUrl,
      isFavListLoading,
      setIsFavListLoading,
      loadingHomeScreen,
      setLoadingHomeScreen,
      fakeChathostList,
      setFakeChathostList,
      haslocalChatFetched, 
      setHaslocalChatFetched
    }),
    [
      chattingList,
      hasFetched,
      followingList,
      liveBroadCastList,
      hasFetchedFav,
      hasFetchedLive,
      profileDetailsData,
      hasFetchedProfile,
      isRecharged,
      hasFakeFetchedLive,
      liveFakeBroadCastList,
      followingFakeList,
      hasFakeFetchedFav,
      currentPage,
      fakeNextPageUrl,
      currentFakePage,
      nextPageUrl,
      fakeChathostList,
      isFavListLoading,
      haslocalChatFetched
    ]
  );

  return (
    <StoreUiContext.Provider value={contextValue}>
      {children}
    </StoreUiContext.Provider>
  );
};

export const useStoreUiContext = () => {
  return useContext(StoreUiContext);
};