import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import PrivateRoute from "./routes/PrivateRoute";
import useViewportHeight from "./constant/ViewPortHeight";
import NotificationToastMessage from "./components/notificationToastMessages/NotificationToastMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import PwaStatus from "./constant/PwaStatus";
import { PwaInstallationIosPopup } from "./components/pwaInstallationIosPopup/PwaInstallationIosPopup";
import PwaInstallationPopup from "./components/pwaInstallationPopup/PwaInstallationPopup";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { PaymentService } from "./services/PaymentService";
import { createFCMToken } from "./firebase/firebase-notifications";


function App() {
  useViewportHeight();

  const paymentService = PaymentService();
  const location = useLocation();
  const navigate = useNavigate();

  const [pwaInstallStatus, setPwaInstallStatus] = useState(PwaStatus.Install);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPwaInstallationPopup, setShowPwaInstallationPopup] = useState(false);
  const [showPwaInstallationPopupForIos, setShowPwaInstallationPopupForIos] = useState(false);
  const dummyUserImage = 'https://starmate2025.blr1.cdn.digitaloceanspaces.com/ringliveProfileImages/10.jpeg';
  // get firebase notification

  onMessage(messaging, (payload) => {
    try {
      if (payload?.data?.order_id) {
        checkPaymentStatus(payload.data.order_id);
      }
    } catch (error) {
      console.error("Error in onMessage handler:", error);
    }
  });

  const checkPaymentStatus = async (orderId) => {
    try {
      const token = localStorage.getItem("WPV_token");
      const statusResponse = token
        ? await paymentService.paymentCheckWhatsappAuth({ order_id: orderId })
        : await paymentService.checkPaymentStatusByOrderId({
          order_id: orderId,
        });
      if (statusResponse?.success) {
        handlePaymentSuccess(statusResponse);
      }
    } catch (error) {
      console.error("Error checking confirm payment status:", error);
    }
  };

  const handlePaymentSuccess = (statusResponse) => {
    const token = localStorage.getItem("WPV_token");
    if (!token) {
      localStorage.setItem("WPV_token", statusResponse.result.token);
      localStorage.setItem(
        "WPV_user_profile_id",
        statusResponse.result.profile_id
      );
      localStorage.setItem("WPV_user_id", statusResponse.result.id);
      localStorage.setItem("WPV_user_name", statusResponse.result.name);
    }
  };

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.log("Service Worker registration failed:", error);
        });
    }
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentRouteName = currentPath.split('/').pop();
    const previousRoute = sessionStorage.getItem('WPV_currentRouteName');
    if (previousRoute === "recharge") {
      // if (currentRouteName === "fake-call") {
      //   sessionStorage.setItem('WPV_currentRouteName', currentRouteName);
      //   return;
      // }
      const token = localStorage.getItem('WPV_token');
      if (!token) {
        navigate('/scratch-reward', { replace: true });
      }
    }
    sessionStorage.setItem('WPV_currentRouteName', currentRouteName);
  }, [location]);

  const createRandomUserProfile = () => {
    localStorage.setItem("WPV_my_profile_image", dummyUserImage)
    const userName = localStorage.getItem("WPV_user_name");
    if (!userName) {
      const name = `GuestUser0${Math.floor(Math.random() * 10)}`;
      localStorage.setItem("WPV_user_name", name);
    }
  }

  const createToken = async () => {
    const fcmToken = await createFCMToken();
    localStorage.setItem("WPV_fcm_token", fcmToken);
  }

  useEffect(() => {
    createToken();
    createRandomUserProfile();
    const token = localStorage.getItem("WPV_token");
    const profileId = localStorage.getItem("WPV_user_profile_id");
    const hostLocation = localStorage.getItem("WPV_host_location");
    if (!token && !profileId) {
      const userId = generate10DigitUserId();
      localStorage.setItem('WPV_user_profile_id', userId);
    }
    if (!hostLocation) {
      fetchNearbyCities();
    }
  }, [])

  const fetchNearbyCities = async () => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by your browser.");
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(
            `https://overpass-api.de/api/interpreter?data=[out:json];node(around:100000,${latitude},${longitude})[place=city];out;`
          );
          if (!response.ok) {
            console.error("Failed to fetch nearby cities.");
            return;
          }
          const data = await response.json();
          const extractedCities = data.elements
            .filter((el) => el.tags && el.tags.name)
            .map((el) => ({
              name: el.tags.name,
              latitude: el.lat,
              longitude: el.lon,
            }));
          localStorage.setItem('WPV_host_location', JSON.stringify(extractedCities));
        } catch (error) {
          console.error(error.message);
        }
      },
      () => {
        const extractedCities = [
          { name: "New Delhi", latitude: 28.6138954, longitude: 77.2090057 },
          { name: "Bahadurgarh", latitude: 28.6933239, longitude: 76.9332373 },
          { name: "Sonipat", latitude: 28.9953758, longitude: 77.0233627 },
          { name: "Noida", latitude: 28.5706333, longitude: 77.3272147 },
          { name: "Rohtak", latitude: 28.9010899, longitude: 76.5801935 },
          { name: "Manesar", latitude: 28.3617283, longitude: 76.9402153 },
          { name: "Bhiwadi", latitude: 28.2039413, longitude: 76.837441 },
          { name: "Ghaziabad", latitude: 28.6711527, longitude: 77.4120356 },
          { name: "Delhi", latitude: 28.6517178, longitude: 77.2219388 },
          { name: "Gurugram", latitude: 28.4646148, longitude: 77.0299194 },
          { name: "Faridabad", latitude: 28.4031478, longitude: 77.3105561 }
        ];
        localStorage.setItem('WPV_host_location', JSON.stringify(extractedCities));
      }
    );
  };


  function generate10DigitUserId() {
    const deviceId = uuidv4();
    const numericHash = deviceId.replace(/[^0-9]/g, '');
    const userId = numericHash.substring(0, 10);
    return userId;
  }

  const checkStandaloneMode = () => {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone === true
    );
  };

  const isIosDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.includes("iphone") ||
      userAgent.includes("ipad") ||
      userAgent.includes("ipod")
    );
  };

  const checkIosStandaloneMode = () => {
    return isIosDevice() && window.navigator.standalone === true;
  };

  const closeIosConfirmationPopup = () => {
    setShowPwaInstallationPopupForIos(false);
  };

  const openPwaApp = () => {
    closePwaConfirmationPopup();
    window.open(window.location.origin, "_blank");
  };


  const handleBeforeInstallPrompt = (event) => {
    const isStandalone = checkStandaloneMode();
    if (!isStandalone) {
      event.preventDefault();
      setDeferredPrompt(event);
      setPwaInstallStatus(PwaStatus.Install);
      setShowPwaInstallationPopup(true);
    }
  };
  const handleIosPwaCheck = () => {
    const isIos = isIosDevice();
    const isStandalone = checkIosStandaloneMode();
    if (isIos && !isStandalone) {
      const alreadyOpenIos = sessionStorage.getItem(
        "already_open_pwa_confirmation_popup_for_ios"
      );
      if (!alreadyOpenIos) {
        setShowPwaInstallationPopupForIos(true);
        sessionStorage.setItem(
          "already_open_pwa_confirmation_popup_for_ios",
          "true"
        );
      }
    }
  };

  const installPwaApplication = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setPwaInstallStatus(PwaStatus.Installing);
        } else {
          closePwaConfirmationPopup();
        }
      });
    }
  };

  const closePwaConfirmationPopup = () => {
    setDeferredPrompt(null);
    setShowPwaInstallationPopup(false);
  };


  const checkPwaInstallation = async () => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", () => {
      setTimeout(() => {
        setPwaInstallStatus(PwaStatus.Installed);
      }, 10000);
    });
  };

  useEffect(() => {
    checkPwaInstallation();
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      window.removeEventListener("appinstalled", () =>
        setPwaInstallStatus(PwaStatus.Installed)
      );
    };
  }, []);

  useEffect(() => {
    handleIosPwaCheck();
  }, []);

  return (
    <>
      <NotificationToastMessage />
      <PrivateRoute />
      {showPwaInstallationPopup && (
        <PwaInstallationPopup
          pwaInstallStatus={pwaInstallStatus}
          handleInstall={installPwaApplication}
          onClose={closePwaConfirmationPopup}
          openApp={openPwaApp}
        />
      )}
      {showPwaInstallationPopupForIos && (
        <PwaInstallationIosPopup
          closeIosConfirmationPopup={closeIosConfirmationPopup}
        />
      )}
    </>
  );
}

export default App;
