import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ element: Component }) => {
  const isAuthenticated = !!localStorage.getItem("WPV_token");
  return isAuthenticated ? <Navigate to="/home" replace /> : <Component />;
};

export default PublicRoute;

