import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StoreUiProvider } from './context/StoreUiContext';
import { ToastProvider } from './context/ToastContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <BrowserRouter>
    <StoreUiProvider>
       <ToastProvider>
          <App />
       </ToastProvider>
    </StoreUiProvider>
    </BrowserRouter>
);

serviceWorkerRegistration.register();

reportWebVitals();
