const API_URL = process.env.REACT_APP_API_URL;


const handleFetch = async (url, method, headers, body = null) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

const handleResponse = async (response) => {
  if (!response.ok) {
    return handleError({ message: 'Something went wrong. Please try again later.' });
  }
  return response.json();
};

const handleError = (error) => {
  if (error.message === 'Failed to fetch') {
    // localStorage.clear();
    // sessionStorage.clear();
    // window.location.href = '/session-expired';
  }
  return { error: error.message };
};

const Get = async (endpoint, headerValue = {}) => {
  const url = `${API_URL}${endpoint}`;
  const headers = {
    ...headerValue,
    ...(Object.keys(headerValue).length === 0 && { 'Content-Type': 'application/json' }),
  };
  return handleFetch(url, 'GET', headers);
};

const Post = async (endpoint, data, headerValue = {}) => {
  const url = `${API_URL}${endpoint}`;
  const headers = {
    ...headerValue,
    ...(Object.keys(headerValue).length === 0 && { 'Content-Type': 'application/json' }),
  };

  return handleFetch(url, 'POST', headers, data);
};

const PostForMultiPartFormData = async (endpoint, formData, headers = {}) => {
  const url = `${API_URL}${endpoint}`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...headers, 
      },
      body: formData,
    });

    // Handle response
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export { Get, Post,PostForMultiPartFormData };
