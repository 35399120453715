import React, { useEffect, useRef, useState } from "react";
import "./NotificationToastMessage.css";
import { useToast } from "../../context/ToastContext";
import notificatioRingtone from "../../assets/videos/notification-ringtone.wav";
import defaultImage from "../../assets/images/placeHolderForGuest.png"

function NotificationToastMessage() {
  const { toastNotification, toastCallback } = useToast();
  const audioVoiceRef = useRef(null);
  const [position, setPosition] = useState(0);
  const startX = useRef(0);
  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    setPosition(0);
    setDismissed(false);
  }, [toastNotification]);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const moveX = e.touches[0].clientX - startX.current;
    setPosition(moveX);
  };

  const handleTouchEnd = () => {
    if (Math.abs(position) > 50) {
      setDismissed(true);
      setTimeout(() => setPosition(position > 0 ? window.innerWidth : -window.innerWidth), 100);
    } else {
      setPosition(0);
    }
  };

  useEffect(() => {
    if (audioVoiceRef.current) {
      audioVoiceRef.current.play();
    }
    return () => {
      if (audioVoiceRef.current) {
        audioVoiceRef.current.pause();
        audioVoiceRef.current.currentTime = 0;
      }
    };
  }, [toastNotification]);

  const handleToastClick = () => {
    if (toastCallback) {
      toastCallback();
    }
  };

  const messageTypeEnus = {
    MESSAGE: 1,
    IMAGE: 2,
    AUDIO: 3,
    VIDEO: 4,
  };

  return (
    <>
      {toastNotification.isVisible && <audio ref={audioVoiceRef} src={notificatioRingtone} />}
      {toastNotification.isVisible && !dismissed && (
        <div
          className="toast-outer"
          style={{
            transform: `translateX(${position}px)`,
            transition: "transform 0.3s ease-out",
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="toast-inner" onClick={handleToastClick}>
            <div className="toast-image-outer">
              <img 
              src={toastNotification.message?.profile_image} 
              alt="user" 
              onError={(e) => (e.target.src = defaultImage)}
              />
            </div>
            <div className="toast-right-sec-outer">
              <div>
                <div className="host-name-outer">
                  <p>{toastNotification.message?.name}</p>
                </div>
                <div className="text-message-outer">
                  <p>
                    {toastNotification.message.data?.type === messageTypeEnus.MESSAGE &&
                      toastNotification.message.data.message}
                    {toastNotification.message.data?.type === messageTypeEnus.IMAGE && `You have a new Media message`}
                    {toastNotification.message.data?.type === messageTypeEnus.AUDIO && `You have a new Media message`}
                    {toastNotification.message.data?.type === messageTypeEnus.VIDEO && `You have a new Media message`}
                  </p>
                </div>
              </div>
              <div className="reply-button">
                <p>Reply</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NotificationToastMessage;
