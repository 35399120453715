import React from 'react'
import './hostUnavailablepopup.css'
import Lottie from 'lottie-react';
import hostUnavailable from '../../../assets/images/sleep.json'

function HostUnavailablePopup({closeHostUnavailablePopup}) {
  return (
    <div className="hostUnavailable-overlay" onClick={closeHostUnavailablePopup}>
            <div className="hostUnavailable-box" onClick={(e) => e.stopPropagation()}>
                <div className='hostUnavailable-text-outer'>
                    <p className='hostUnavailable-text'>Opps!!</p>
                    <p className='hostUnavailable-text-info'>The host is not available to take your call.</p>
                </div>
                <div className="hostUnavailable-button" onClick={closeHostUnavailablePopup}>
                    <p>Got It</p>
                </div>
                <div className='hostUnavailable-top-animation'>
                    <Lottie animationData={hostUnavailable}/>
                </div>
            </div>
        </div>
  )
}

export default HostUnavailablePopup