import { Get, Post, PostForMultiPartFormData } from "../utils/ApiUtility";


export const ProfileService = () => {

  const getToken = () => localStorage.getItem('WPV_token');

  const getUserRechargeStatus = async ()=>{
    const token = getToken();
    const headers = {
      appid: "8",
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    return await Get('appsettings',headers); 
}

  const getPlanlist = async () => {
    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "8",
      'Content-Type': 'application/json'
    };
    return await Get("planlist", headers);
  };

  const getFollowingList = async (page) => {
    const token = getToken();
    const url = `get-follower-list-web?page=${page}`
    const headers = { 
      Authorization: `Bearer ${token}`,
      appid: "8",
      'Content-Type': 'application/json'
    };

    return await Get( url , headers);
  }

  const followHost = async(id) => {
    const token = getToken();
    const url = `follow?following_id=${id}`
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "8",
      'Content-Type': 'application/json'
    };

    return await Post(url, {}, headers);
  }

  const getMyBalance = async () => {
    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "8",
      'Content-Type': 'application/json'
    };
    return await Get("points", headers);
  };

  const getOfflineMessages = async()=> {
    const url = `get-bulk-message-list-in-web`;
    const headers = {
      appid: "8",
      'Content-Type': 'application/json'
    };
    return await Get(url,headers);
  };

  const getProfileDetails = async () => {
    const token = getToken();
    const url = 'details'
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "8",
      'Content-Type': 'application/json'
    };
    return await Post(url, {}, headers);
  }

  const getUserCallHistory = async (page) => {
    const token = getToken();
    const url = `call-history?page=${page}`
    const headers = {
      Authorization: `Bearer ${token}`,
      appid: "8",
      'Content-Type': 'application/json'
    };
    return await Get(url, headers);
  }

  const getProfileDetailByProfileId = async (profileId) => {
    const token = getToken();
    const url = `getdatabyprofileid?profile_id=${profileId}`;
    const headers = { 
      Authorization: `Bearer ${token}`,
      appid: "8",
      "Content-Type": "application/json",
    };
    return await Get(url, headers);
  }

  const getPlanlistWeb = async () => {
    const headers = {
      appid: "8",
      'Content-Type': 'application/json'
    };
    return await Get("planlistweb", headers);
  };
  const getDataByProfileIdPublic = async (profileId) => {
    const url = `getdatabyprofileidpublic?profile_id=${profileId}`;
    const headers = { 
      appid: "8",
      "Content-Type": "application/json"
    };
    return await Get(url, headers);
  }
  
  return { getPlanlist, getFollowingList, followHost, getMyBalance, getProfileDetails, getUserCallHistory,getProfileDetailByProfileId,getUserRechargeStatus,getOfflineMessages,getPlanlistWeb,getDataByProfileIdPublic} ;
};

